<script>
import ChatFooter from "./chat-box/ChatFooter.vue";
import ChatHeader from "./chat-box/ChatHeader.vue";
import ChatContent from "./chat-box/ChatContent.vue";
import { workspaceNew } from "@/api/factory.js";
import FileList from "./file-list/FileList.vue";
import TaskList from "./task-list/TaskList.vue";

export default {
  name: "Chatbox",

  components: { ChatFooter, ChatHeader, ChatContent, FileList, TaskList },

  props: {
    selectedWorkspace: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tabs: ["Chat", "Files", "Tasks"],
      selectedTab: "Chat",
      chatHistory: [],
      currentPage: 1,
      itemsPerPage: 50,
      totalItems: 0,
      isLoading: true,
      refreshIntervalId: "",
    };
  },

  computed: {
    _chatHistory() {
      const cloned = JSON.parse(JSON.stringify(this.chatHistory));
      cloned.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));

      const grouped = cloned.reduce((acc, cur) => {
        const date = cur.createdAt.split("T")[0];
        acc[date] = acc[date] ? [...acc[date], cur] : [cur];
        return acc;
      }, {});

      const mapped = Object.keys(grouped).map((group) => ({
        id: group,
        date: group,
        items: grouped[group],
      }));

      return mapped;
    },
  },

  watch: {
    selectedWorkspace: {
      async handler() {
        if (this.selectedWorkspace.id) {
          this.currentPage = 1;
          this.chatHistory = [];
          await this.getChatHistory();
          this.scrollToBottom();
        }
      },
    },

    selectedTab: {
      handler() {
        if (this.selectedTab === "Chat") {
          this.scrollToBottom();
        }
      },
    },
  },

  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const chatContent = document.getElementById("chat-content");
        chatContent.scrollTop = chatContent.scrollHeight;
      });
    },

    async addUser(user) {
      const payload = {
        itemId: this.$nano.id(),
        type: "user",
        action: "added",
        user,
        createdBy: this.$store.state.session.email,
        createdAt: new Date().toISOString(),
      };

      this.chatHistory.push(payload);

      this.scrollToBottom();
      await this.sendMessage(payload);
      this.$emit("refresh");
    },

    async removeUser(user) {
      const payload = {
        itemId: this.$nano.id(),
        type: "user",
        action: "removed",
        user,
        createdBy: this.$store.state.session.email,
        createdAt: new Date().toISOString(),
      };

      this.chatHistory.push(payload);

      this.scrollToBottom();
      await this.sendMessage(payload);
      this.$emit("refresh");
    },

    addLoader() {
      this.chatHistory.push({
        id: this.$nano.id(),
        type: "loader",
        message: "",
        createdBy: this.$store.state.session.email,
        createdAt: new Date().toISOString(),
      });
    },

    async addTask(task) {
      this.addLoader();

      const payload = {
        itemId: this.$nano.id(),
        type: "task",
        ...task,
        createdBy: this.$store.state.session.email,
        createdAt: new Date().toISOString(),
      };

      await this.sendMessage(payload);
      this.$emit("refresh");

      clearInterval(this.refreshIntervalId);

      if (!this.refreshIntervalId) {
        this.refreshIntervalId = setInterval(() => this.getUnreadItems(), 5000);
      }

      this.getUnreadItems();
    },

    async addRequest(request) {
      this.addLoader();

      const payload = {
        itemId: this.$nano.id(),
        type: "workflow",
        ...request,
        createdBy: this.$store.state.session.email,
        createdAt: new Date().toISOString(),
      };

      await this.sendMessage(payload);
      this.$emit("refresh");

      clearInterval(this.refreshIntervalId);

      if (!this.refreshIntervalId) {
        this.refreshIntervalId = setInterval(() => this.getUnreadItems(), 5000);
      }

      this.getUnreadItems();
    },

    _sendMessage(message) {
      const payload = {
        itemId: this.$nano.id(),
        type: "message",
        message,
        createdBy: this.$store.state.session.email,
        createdAt: new Date().toISOString(),
        isDeleted: 0,
      };
      this.chatHistory.push(payload);

      this.scrollToBottom();
      this.sendMessage(payload);
    },

    async uploadFiles(files) {
      for (const file of files) {
        this.addLoader();

        this.scrollToBottom();

        try {
          await workspaceNew.uploadFile(file.file, this.selectedWorkspace);
        } catch (error) {
          console.error(error);
        }
      }

      this.$emit("refresh");

      clearInterval(this.refreshIntervalId);

      if (!this.refreshIntervalId) {
        this.refreshIntervalId = setInterval(() => this.getUnreadItems(), 5000);
      }

      await this.getUnreadItems();
    },

    async getChatHistory() {
      this.isLoading = true;

      const { error, payload } = await workspaceNew.getChatHistory(
        this.selectedWorkspace.id,
        this.currentPage,
        this.itemsPerPage
      );

      this.isLoading = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!this.refreshIntervalId) {
        this.refreshIntervalId = setInterval(
          () => this.getUnreadItems(),
          15000
        );
      }

      const { meta, data } = payload;
      this.totalItems = meta.totalItems || 0;
      this.chatHistory.push(...data[0].value);
    },

    async sendMessage(message) {
      const { error } = await workspaceNew.sendMessage(
        this.selectedWorkspace.id,
        message
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    loadMore() {
      if (this.chatHistory.length < this.totalItems) {
        this.currentPage += 1;
        this.getChatHistory();
      }
    },

    async deleteItem(itemId) {
      const { error } = await workspaceNew.deleteChatItem(
        this.selectedWorkspace.id,
        itemId
      );

      if (error) {
        this.$alert.error(error);
        return;
      } else {
        const itemIdx = this.chatHistory.findIndex(
          (item) => item.itemId === itemId
        );
        this.chatHistory[itemIdx].isDeleted = 1;
      }
    },

    async getUnreadItems() {
      if (this.$route.name !== "workspaces-overview") {
        clearInterval(this.refreshIntervalId);
        return;
      }
      this.$emit("refresh");
      const { error, payload } = await workspaceNew.getChatHistory(
        this.selectedWorkspace.id,
        1,
        1000,
        true
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      const filtered = this.chatHistory.filter(
        (item) => item.type !== "loader"
      );
      this.chatHistory = filtered;

      if (data[0].value.length > 0) {
        const _data = data[0].value.filter((item) => {
          if (item.createdBy === this.$store.state.session.email) {
            return ["task", "workflow", "file"].includes(item.type);
          }
          return true;
        });
        this.chatHistory.push(..._data);
      }
    },
  },
};
</script>

<template>
  <div
    :style="{
      width: $q.fullscreen.isActive
        ? 'calc(100vw - 576px)'
        : 'calc(100vw - 628px)',
    }"
  >
    <ChatHeader
      :selected-workspace="selectedWorkspace"
      :tabs="tabs"
      :selected-tab="selectedTab"
      @select="(tab) => (selectedTab = tab)"
      @add="addUser"
      @remove="removeUser"
    />

    <template v-if="selectedTab === 'Chat'">
      <ChatContent
        :chat-history="_chatHistory"
        :is-loading="isLoading"
        @loadMore="loadMore"
        @delete="deleteItem"
      />
      <ChatFooter
        :selected-workspace="selectedWorkspace"
        @send="_sendMessage"
        @upload="uploadFiles"
        @addTask="addTask"
        @addRequest="addRequest"
      />
    </template>

    <FileList
      v-if="selectedTab === 'Files'"
      :selected-workspace="selectedWorkspace"
    />

    <TaskList
      v-if="selectedTab === 'Tasks'"
      :selected-workspace="selectedWorkspace"
    />
  </div>
</template>

<style lang="scss">
.user-tag {
  font-weight: 500;
  color: var(--secondary);
}
</style>
